import BaseService from '@src/services/BaseService'
import axios from "axios";

class JustificationService extends BaseService {
  constructor() {
    super('justifications')
  }

  fetchAll(params) {
    return axios.post(`${this.getUrl()}`, params)
  }
}

export default new JustificationService()
