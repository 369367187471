<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nTitle" :show-slot="true">
            <b-button
                class="btn btn-pdv-gradient-yellow mr-1"
                @click="filterWarnings"
            >
                <i class="fe-alert-circle mr-1" />
                {{ getI18n('POS_CHECK', 'BUTTONS.with_warnings_filter') }}
            </b-button>
        </PageHeader>
        <div class="card-body">
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :display-selection-records-per-page="true"
                :additional-parameters="additionalParameters"
                :fields="posCheckFields"
                :sort-order="sortOrder">
                <template slot="battery" slot-scope="props">
                    <b-row>
                        <battery-table :value="props.rowData.battery_in" />
                    </b-row>
                    <b-row class="mt-1">
                        <battery-table :value="props.rowData.battery_out" />
                    </b-row>
                </template>
                <template slot="datetime_in" slot-scope="props">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="formatCheckInfo(props.rowData.datetime_in, props.rowData.distance_in)" />
                </template>
                <template slot="datetime_out" slot-scope="props">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="formatCheckInfo(props.rowData.datetime_out, props.rowData.distance_out)" />
                </template>
                <template slot="file" slot-scope="props">
                    <b-row>
                        {{ getI18n('POS_CHECK', 'FIELDS.in') }}
                        <a v-if="props.rowData.file && props.rowData.file.url"
                            :href="props.rowData.file.url"
                            target="_blank">
                            <i class="fe-camera ml-1"/>
                        </a>
                        <i v-else class="fe-camera-off ml-1" />
                    </b-row>
                    <b-row class="mt-1">
                        {{ getI18n('POS_CHECK', 'FIELDS.out') }}
                        <a v-if="props.rowData.fileOut && props.rowData.fileOut.url"
                            :href="props.rowData.fileOut.url"
                            target="_blank"
                        >
                            <i class="fe-camera ml-1"/>
                        </a>
                        <i v-else class="fe-camera-off ml-1" />
                    </b-row>
                </template>
                <template slot="details" slot-scope="props">
                    <div class="table-button-container">
                        <detail-table-button @onClick="openModalScript(props.rowData)" />
                    </div>
                </template>
            </api-mode-vuetable>
        </div>
        <pos-check-script-modal ref="posCheckScriptModal" />
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import ApiModeVuetable from '@components/ApiModeVuetable'
import PosVisitedService from '@src/services/dashboards/PosVisitedService'
import posCheckFields from '@src/fields/posCheckFields'
import BatteryTable from '@/src/components/BatteryTable.vue'
import DetailTableButton from '@/src/components/DetailTableButton.vue'
import posCheckScriptModal from '@views/posCheck/posCheckScriptModal.vue'

export default {
    components: {
        PageHeader,
        ApiModeVuetable,
        BatteryTable,
        DetailTableButton,
        posCheckScriptModal,
    },
    mixins: [posCheckFields],

    props: {
        parameters: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'users|users.name',
                    direction: 'asc',
                },
                {
                    field: 'datetime_in',
                    direction: 'asc',
                },
            ],
            additionalParameters: {
                ...this.parameters,
                with: ['user', 'pointOfSale', 'file', 'fileOut'],
                with_warnings: this.withWarnings,
            },
            withWarnings: false,
        }
    },
    computed: {
        i18nTitle() {
            return `${this.getI18n('DASHBOARDS', 'TITLES.pos_visited')} ${this.withWarnings ? `(${this.getI18n('POS_CHECK', 'BUTTONS.with_warnings_filter')})` : ''}`
        },
    },
    watch: {
        parameters(newParameters) {
            this.additionalParameters = {
                ...newParameters,
                with: ['user', 'pointOfSale', 'file', 'fileOut'],
                with_warnings: this.withWarnings,
            }
        },
    },
    methods: {
        filterWarnings() {
            this.withWarnings = !this.withWarnings
            this.additionalParameters = {
                ...this.additionalParameters,
                with: ['user', 'pointOfSale', 'file', 'fileOut'],
                with_warnings: this.withWarnings,
            }
            this.refreshTable()
        },
        fetch(url, params) {
            return PosVisitedService.fetchVuetable(url, params)
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        distanceClass(percentile) {
            return (
                'text-center aligned font-weight-bold ' +
                (percentile >= 80
                    ? 'table-success'
                    : percentile >= 50
                    ? 'table-warning'
                    : 'table-danger')
            )
        },
        openModalScript(data) {
            this.$nextTick(() => this.$refs.posCheckScriptModal.showModal(data))
        }
    },
}
</script>
