<template>
    <form-modal
        ref="formModal"
        :title="i18nJustification">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nJustification"
                    :operation="'deleted'"
                />
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="getI18n('USERS', 'TITLES.user')"
                            class="label-pdv mb-0">
                            <div class="text-pdv">
                                <p v-if="justificationModal.user">
                                    {{ justificationModal.user.name }}
                                </p>
                                <p v-else>
                                    {{ getI18n('COMMON', 'uninformed') }}
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :label="getI18n('BUSINESS_UNITS', 'TITLES.business_unit')"
                            class="label-pdv mb-0">
                            <div class="text-pdv">
                                <p v-if="justificationModal.businessUnit">
                                    {{ justificationModal.businessUnit.name }}
                                </p>
                                <p v-else>
                                    {{ getI18n('COMMON', 'uninformed') }}
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :label="getI18n('POS', 'TITLES.pos')"
                            class="label-pdv mb-0">
                            <div class="text-pdv">
                                <p v-if="justificationModal.pointOfSale">
                                    {{ justificationModal.pointOfSale.name }}
                                </p>
                                <p v-else>
                                    {{ getI18n('COMMON', 'uninformed') }}
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :label="getI18n('JUSTIFICATION', 'TITLES.justification_type')"
                            class="label-pdv mb-0">
                            <div class="text-pdv">
                                <p v-if="justificationModal.justificationType">
                                    {{ justificationModal.justificationType.name }}
                                </p>
                                <p v-else>
                                    {{ getI18n('COMMON', 'uninformed') }}
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :label="getI18n('JUSTIFICATION', 'TITLES.justification')"
                            class="label-pdv mb-0">
                            <div class="text-pdv">
                                <p v-if="justificationModal.justification">
                                    {{ justificationModal.justification }}
                                </p>
                                <p v-else>
                                    {{ getI18n('COMMON', 'uninformed') }}
                                </p>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-if="!readOnly" v-slot:footer>
            <div class="d-flex justify-content-between">
                <save-button
                    ref="okButton"
                    child-class="col-sm-5 float-left"
                    :busy="submitLoading"
                    :disabled="deleteLoading || justificationModal.status"
                    :title="i18nApprove"
                    @onClick="handleOk"
                />

                <delete-button
                    ref="deleteButton"
                    child-class="col-sm-5 float-right"
                    :busy="deleteLoading"
                    :disabled="submitLoading"
                    @onClick="handleDelete"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import DeleteButton from '@/src/components/DeleteButton'
import SaveButton from '@/src/components/SaveButton'


import JustificationService from '@src/services/JustificationService'

const i18nKey = 'JUSTIFICATION'
const i18nCommon = 'COMMON'

export default {
    components: {
        ConfirmationFormModal,
        FormModal,
        DeleteButton,
        SaveButton,
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    business_unit_id: 0,
                    businessUnit: {
                        id: 0,
                        name: '',
                    },
                    justification_type_id: 0,
                    justificationType: {
                        id: 0,
                        name: '',
                    },
                    work_schedule_id: 0,
                    pointOfSale: {
                        id: 0,
                        name: ''
                    },
                    user_id: 0,
                    user: {
                        id: 0,
                        name: ''
                    },
                    justification: '',
                    justification_date: null,
                    status: false,
                }
            },
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            justificationModal: this.modal,
            readOnly: false,
            submitLoading: false,
            deleteLoading: false,
        }
    },
    validations: {
        justificationModal: {},
    },
    computed: {
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nFlagInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nApprove() {
            return this.getI18n(i18nCommon, 'approve')
        },
        i18nNewPosFlag() {
            return this.getI18n(i18nKey, 'BUTTONS.new_flag')
        },
        i18nEditPosFlag() {
            return `${this.getI18n(i18nCommon, 'edit')} ${this.getI18n(
                i18nKey,
                'TITLES.justification'
            )}`
        },
        i18nJustification() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.justification',
                modifier: 1,
            })
        },
    },
    methods: {
        showModal(readOnly) {
            this.readOnly = readOnly
            this.justificationModal = this.modal
            this.submitLoading = false
            this.deleteLoading = false
            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.justificationModal = Object.assign(this.justificationModal, {
                id: 0,
                business_unit_id: 0,
                businessUnit: {
                    id: 0,
                    name: '',
                },
                justification_type_id: 0,
                justificationType: {
                    id: 0,
                    name: '',
                },
                work_schedule_id: 0,
                pointOfSale: {
                    id: 0,
                    name: ''
                },
                user_id: 0,
                user: {
                    id: 0,
                    name: ''
                },
                justification: '',
                justification_date: null,
                status: false,
            })

            this.$v.justificationModal.$reset()
        },
        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk(event) {
            this.$v.justificationModal.$touch()
            if (!this.$v.justificationModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },
        async handleSubmit() {
            try {
                await JustificationService
                    .update(this.justificationModal.id, {'status': true})
                        .then((response) => {
                            const statusCode = response.status.toString()

                            if (statusCode.charAt(0) === '2') {
                                this.$emit('refresh')
                                this.resetValidation()
                                this.$refs.formModal.hide()
                                this.positiveFeedback(this.i18nJustification, 'edited', 3)
                            }
                            else {
                                this.negativeFeedback(this.i18nJustification, response.message)
                            }
                        })
                        .catch((error) => {
                            this.negativeFeedback(this.i18nJustification, error.message)
                        })
            }
            finally {
                this.submitLoading = false
            }
        },
        handleDelete() {
            if (this.deleteLoading) {
                return
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteJustification)
        },
        async deleteJustification(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            try {
                this.$v.justificationModal.$touch()
                this.deleteLoading = true

                await JustificationService.delete(this.justificationModal.id)
                    .then((response) => {
                        const statusCode = response.status.toString()

                        if (statusCode.charAt(0) === '2') {
                            this.$emit('refresh')
                            this.resetValidation()
                            this.$refs.formModal.hide()
                            this.positiveFeedback(this.i18nJustification, 'deleted', 3)
                        }
                        else {
                            this.negativeFeedback(this.i18nJustification, response.message)
                        }
                    })
                    .catch((error) => {
                        this.negativeFeedback(this.i18nJustification, error.message)
                    });
            }
            finally {
                this.deleteLoading = false
            }
        },
    },
}
</script>
