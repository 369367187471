<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nPeriod"
                            label-for="filter-started-at-date-time"
                            class="required label-pdv">
                            <date-range-picker
                                :value.sync="filterModal.date"
                                :max-date="new Date()"
                                :disable-clean-button="true"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nTeam"
                            label-for="filter-team"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-team'"
                                ref="teamMultiselect"
                                v-model="filterModal.teams"
                                :service="'teams'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nRole"
                            label-for="filter-role"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-role'"
                                ref="roleMultiselect"
                                v-model="filterModal.roles"
                                :service="'roles'"
                                label="alias"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                                :disabled="$store.getters['auth/isPromotor']"
                                @input="changeRoles"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="filterModal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                                :disabled="$store.getters['auth/isPromotor']"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import moment from 'moment';

const i18nKey = 'IMPORT_JOBS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DateRangePicker,
        SaveButton,
        ClearFilterButton,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            parameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {},
    computed: {
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nTeam() {
            return this.getI18n('TEAMS', 'TITLES.team')
        },
        i18nRole() {
            return this.getI18n('SECURITY', 'TITLES.role')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.changeRoles()
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                roles: [],
                users: [],
                teams: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        },
        changeRoles() {
            if (this.filterModal.users.length > 0 && this.filterModal.roles.length > 0) {
                this.filterModal.users = this.filterModal.users.filter(user => user.role.length > 0 && this.filterModal.roles.some(role => role.id === user.role[0].id))
            }

            this.usersParameters = this.formatParameters({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                role_id: this.filterModal.roles.map((role) => role.id),
                active: true,
            })

            this.$nextTick(() => {
                this.$refs.userMultiselect.loadOptions(this.usersParameters)
            })
        }
    },
}
</script>
