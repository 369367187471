import BaseService from '@src/services/BaseService'

class UserInfoPendenciesService extends BaseService {
    constructor() {
        super('dashboards/user-info-pendencies')
    }
}

export default new UserInfoPendenciesService()

