import BaseService from '@src/services/BaseService'

class ResumeService extends BaseService {
    constructor() {
        super('dashboards/resume')
    }


}

export default new ResumeService()

