<template>
    <form-modal
        ref="formModal"
        :title="i18nUsers"
        size="lg">
        <template v-slot:form>
            <b-tabs
                id="resume-users-work-modal-tabs"
                class="tabs-pdv"
            >
                <b-tab :title="i18nWorkingUsers">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetchWorking"
                        :additional-parameters="parameters"
                        :fields="fields"
                        :sort-order="sortOrder"
                        :per-page="perPage"
                    />
                </b-tab>
                <b-tab :title="i18nNotWorkingUsers">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetchNotWorking"
                        :additional-parameters="parameters"
                        :fields="fields"
                        :sort-order="sortOrder"
                        :per-page="perPage"
                    />
                </b-tab>
            </b-tabs>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import WidgetService from '@src/services/dashboards/widgets/WidgetService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import userFields from '@src/fields/userFields'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        vuetableFormatters,
        userFields,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterText: '',
            parameters: {},
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            perPage: 10,
        }
    },
    computed: {
        i18nUsers() {
            return this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })
        },
        i18nWorkingUsers() {
            return this.getI18n(i18nKey, 'TITLES.working_users')
        },
        i18nNotWorkingUsers() {
            return `${this.i18nUsers} ${this.getI18n(i18nKey, 'TITLES.inactive')}`
        },
        fields() {
            return this.userFields.filter((field) => field.name === 'name' || field.name === 'email')
        },
    },
    methods: {
        fetchWorking(url, params) {
            return WidgetService.fetchVuetable('working-users', params)
        },
        fetchNotWorking(url, params) {
            return WidgetService.fetchVuetable('not-working-users', params)
        },
        showModal(parameters) {
            this.parameters = parameters
            this.$refs.formModal.show()
        },
    },
}
</script>
