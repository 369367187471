<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            justificationFields:
            [
                {
                    name: 'user.name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'business_unit.name',
                    title: this.getI18n(
                        'BUSINESS_UNITS',
                        'TITLES.business_unit'
                    ),
                    sortField: 'business_units|business_units.name',
                },
                {
                    name: 'point_of_sale.name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                },
                {
                    name: 'justificationType.name',
                    title: this.getI18n(
                        'JUSTIFICATION',
                        'TITLES.justification_type'
                    ),
                    sortField: 'justification_types|justification_types.name',
                },
                {
                    name: 'justification_date',
                    title: this.getI18n('COMMON', 'date'),
                    sortField: 'justification_date',
                },
                {
                    name: 'status',
                    title: this.getI18n('COMMON', 'status'),
                    sortField: 'status',
                    formatter: this.formartJustificationStatus,
                },
                {
                    name: 'file',
                    title: this.getI18n('COMMON', 'file'),
                    formatter: this.formartFile,
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
