<template>
    <form-modal
        ref="formModal"
        :title="i18nUsers"
        size="lg">
        <template v-slot:form>
            <b-tabs
                id="resume-users-planned-modal-tabs"
                class="tabs-pdv"
            >
                <b-tab :title="i18UnplannedUsers">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetchUnplannedUsers"
                        :additional-parameters="parameters"
                        :fields="fields"
                        :sort-order="sortOrder"
                        :per-page="perPage"
                    />
                </b-tab>
                <b-tab :title="i18PlannedUsers">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetchPlannedUsers"
                        :additional-parameters="parameters"
                        :fields="fields"
                        :sort-order="sortOrder"
                        :per-page="perPage"
                    />
                </b-tab>
            </b-tabs>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import WidgetService from '@src/services/dashboards/widgets/WidgetService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import userFields from '@src/fields/userFields'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        vuetableFormatters,
        userFields,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterText: '',
            parameters: {},
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            perPage: 10,
        }
    },
    computed: {
        i18nUsers() {
            return this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })
        },
        i18PlannedUsers() {
            return this.getI18n(i18nKey, 'TITLES.planned_users')
        },
        i18UnplannedUsers() {
            return this.getI18n(i18nKey, 'TITLES.unplanned_users')
        },
        fields() {
            return this.userFields.filter((field) => field.name === 'name' || field.name === 'email')
        },
    },
    methods: {
        fetchUnplannedUsers(url, params) {
            return WidgetService.fetchVuetable('unplanned-users', params)
        },
        fetchPlannedUsers(url, params) {
            return WidgetService.fetchVuetable('planned-users', params)
        },
        showModal(parameters) {
            this.parameters = parameters
            this.$refs.formModal.show()
        },
    },
}
</script>
