<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import posNotVisited from '@src/router/views/dashboards/resume/posNotVisited'
import posVisited from '@src/router/views/dashboards/resume/posVisited'
import userInfo from '@src/router/views/dashboards/resume/userInfo'
import pendingByUser from '@src/router/views/dashboards/resume/pendingByUser'
import filterVuetable from '@src/mixins/filterVuetable'
import userFields from '@src/fields/userFields'
import businessUnitCheckFields from '@src/fields/businessUnitCheckFields'
import justificationFields from '@src/fields/justificationFields'
import chartsResume from '@/src/router/views/dashboards/resume/chartsResume.vue';
import resumeFilterModal from '@/src/router/views/dashboards/resume/resumeFilterModal.vue';
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        Breadcrumb,
        DateRangePicker,
        multiSelectWithService,
        MoreFiltersLabelButton,
        FilterButton,
        posNotVisited,
        posVisited,
        userInfo,
        pendingByUser,
        chartsResume,
        resumeFilterModal,
    },
    mixins: [
        filterVuetable,
        userFields,
        businessUnitCheckFields,
        justificationFields,
    ],

    data() {
        return {
            i18nKey,
            i18nCommon,
            additionalParameters: this.createAdditionalParameters(),
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                roles: this.$store.state.auth.defaultDashboardRole ? [this.$store.state.auth.defaultDashboardRole] : [],
                users: this.userId() ? [this.$store.state.auth.currentUser] : [],
                teams: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.resume'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nRole() {
            return this.getI18n('SECURITY', 'TITLES.role')
        },
        posVisitedParameters() {
            const parameters = {}
            Object.assign(parameters, this.additionalParameters)
            parameters.with = ['pointOfSale', 'user', 'file', 'fileOut']
            return parameters
        },
    },
    mounted() {
        if (this.$store.state.auth.defaultDashboardRole) {
            this.filters.roles = [this.$store.state.auth.defaultDashboardRole]
        }
        if (this.userId()) {
            this.filters.users = [this.$store.state.auth.currentUser]
        }
        this.$refs.chartsResume.resumeFetch(this.additionalParameters)
    },
    methods: {
        userId() {
            if (this.$store.state.auth.currentUser && this.$store.getters['auth/isPromotor']) {
                return this.$store.state.auth.currentUser.id
            }
            return null
        },
        createAdditionalParameters() {
            if (!this.filters) {
                this.filters = {
                    date: {
                        start: moment().startOf('day').toDate(),
                        end: moment().startOf('day').toDate()
                    },
                    business_units: [],
                    roles: this.$store.state.auth.defaultDashboardRole ? [this.$store.state.auth.defaultDashboardRole] : [],
                    users: this.userId() ? [this.$store.state.auth.currentUser] : [],
                    teams: []
                }
            }

            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                business_unit_id: this.filters.business_units.map(
                    (businessUnit) => businessUnit.id
                ),
                role_id: this.filters.roles.map((role) => role.id),
                user_id: this.filters.users.map((user) => user.id),
                team_id: this.filters.teams.map((team) => team.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshEverything()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.resumeFilterModal.showModal(this.filters))
        },

        refreshEverything() {
            this.$refs.posNotVisited.refreshTable()
            this.$refs.posVisited.refreshTable()
            this.$refs.userInfo.refreshTable()
            this.$refs.pendingByUser.refreshTable()
            this.$refs.chartsResume.resumeFetch(this.additionalParameters)
        },

        changeRoles() {
            if (this.filters.users.length > 0 && this.filters.roles.length > 0) {
                this.filters.users = this.filters.users.filter(user => user.role.length > 0 && this.filters.roles.some(role => role.id === user.role[0].id))
            }
        }
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nRole"
                                class="label-pdv"
                                label-for="filter-role"
                            >
                                <multiSelectWithService
                                    :id="'filter-role'"
                                    ref="roleMultiselect"
                                    v-model="filters.roles"
                                    :service="'roles'"
                                    label="alias"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="$store.getters['auth/isPromotor']"
                                    @input="changeRoles"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" @onClick="filter" />
                    </div>
                </div>
            </div>
        </div>

        <charts-resume ref="chartsResume" />
        <userInfo ref="userInfo" :parameters="additionalParameters" />
        <posVisited ref="posVisited" :parameters="posVisitedParameters" />
        <posNotVisited ref="posNotVisited" :parameters="additionalParameters" />
        <pendingByUser
            v-if="!$store.getters['auth/isPromotor']"
            ref="pendingByUser"
            :parameters="additionalParameters"
        />

        <resume-filter-modal
            ref="resumeFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
