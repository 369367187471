<template>
    <form-modal
        ref="formModal"
        :title="i18nVisits"
        size="lg">
        <template v-slot:form>
            <b-tabs
                id="resume-visits-modal-tabs"
                class="tabs-pdv"
            >
                <b-tab :title="i18nPos">
                    <b-tabs
                        id="resume-visits-pos-modal-tabs"
                        class="tabs-pdv"
                    >
                        <b-tab :title="i18nVisited">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchPosVisited"
                                :additional-parameters="posVisitedParameters"
                                :fields="posCompletedFields"
                                :sort-order="completedSortOrder"
                                :per-page="perPage"
                            />
                        </b-tab>
                        <b-tab :title="i18nNotVisited">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchPosNotVisited"
                                :additional-parameters="parameters"
                                :fields="posUncompletedFields"
                                :per-page="perPage"
                            />
                        </b-tab>
                        <b-tab :title="i18nJustified">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchPosJustified"
                                :additional-parameters="parameters"
                                :fields="posJustifiedFields"
                                :sort-order="justifiedSortOrder"
                                :per-page="perPage"
                            />
                        </b-tab>
                    </b-tabs>
                </b-tab>
                <b-tab :title="i18nBusinessUnit">
                    <b-tabs
                        id="resume-visits-business-unit-modal-tabs"
                        class="tabs-pdv"
                    >
                        <b-tab :title="i18nVisited">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchBusinessUnitVisited"
                                :additional-parameters="parameters"
                                :fields="completedFields"
                                :sort-order="completedSortOrder"
                                :per-page="perPage"
                            />
                        </b-tab>
                        <b-tab :title="i18nNotVisited">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchBusinessUnitNotVisited"
                                :additional-parameters="parameters"
                                :fields="uncompletedFields"
                                :per-page="perPage"
                            />
                        </b-tab>
                        <b-tab :title="i18nJustified">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetchBusinessUnitJustified"
                                :additional-parameters="parameters"
                                :fields="justifiedFields"
                                :sort-order="justifiedSortOrder"
                                :per-page="perPage"
                            />
                        </b-tab>
                    </b-tabs>
                </b-tab>
            </b-tabs>
        </template>
    </form-modal>
</template>

<script>
import PosVisitedService from '@src/services/dashboards/PosVisitedService'
import WidgetService from '@src/services/dashboards/widgets/WidgetService'

import FormModal from '@/src/components/FormModal.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import businessUnitCheckFields from '@src/fields/businessUnitCheckFields'
import justificationFields from '@src/fields/justificationFields'
import posCheckFields from '@src/fields/posCheckFields'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        vuetableFormatters,
        businessUnitCheckFields,
        justificationFields,
        posCheckFields,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterText: '',
            parameters: {},
            posVisitedParameters: {},
            perPage: 10,
            completedSortOrder: [
                {
                    field: 'datetime_in',
                    direction: 'desc',
                },
            ],
            // uncompletedSortOrder: [
            //     {
            //         field: 'name',
            //         direction: 'desc',
            //     },
            // ],
            justifiedSortOrder: [
                {
                    field: 'justification_date',
                    direction: 'desc',
                },
            ],
            posUncompletedFields: [
                {
                    name: 'user_name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    // sortField: 'user_name',
                },
                {
                    name: 'pos_name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    // sortField: 'point_of_sale_name',
                },
                {
                    name: 'date',
                    title: this.getI18n(i18nCommon, 'date'),
                    // sortField: 'date',
                    formatter: this.formatDate,
                },
            ],
            uncompletedFields: [
                {
                    name: 'user_name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    // sortField: 'user_name',
                },
                {
                    name: 'business_unit_name',
                    title: this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit'),
                    // sortField: 'business_unit_name',
                },
                {
                    name: 'point_of_sale_name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    // sortField: 'point_of_sale_name',
                },
                {
                    name: 'mix_name',
                    title: this.getI18n('MIXES', 'TITLES.mix'),
                    // sortField: 'mix_name',
                },
                {
                    name: 'date',
                    title: this.getI18n(i18nCommon, 'date'),
                    // sortField: 'date',
                    formatter: this.formatDate,
                },
            ],
            posJustifiedFields: [
                {
                    name: 'user_name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    // sortField: 'user_name',
                },
                {
                    name: 'pos_name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    // sortField: 'point_of_sale_name',
                },
                {
                    name: 'justification_date',
                    title: this.getI18n('COMMON', 'date'),
                    // sortField: 'justification_date',
                },
                {
                    name: 'status',
                    title: this.getI18n('COMMON', 'status'),
                    // sortField: 'status',
                    formatter: this.formartJustificationStatus,
                },
            ],
        }
    },
    computed: {
        i18nVisits() {
            return this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'visit',
                        modifier: 2,
                    })
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: 'POS',
                suffix: 'TITLES.pos',
                modifier: 2,
            })
        },
        i18nBusinessUnit() {
            return this.getI18nModified({
                prefix: 'BUSINESS_UNITS',
                suffix: 'TITLES.business_unit',
                modifier: 2,
            })
        },
        i18nVisited() {
            return this.getI18n(i18nKey, 'TITLES.pos_visited')
        },
        i18nNotVisited() {
            return this.getI18n(i18nKey, 'TITLES.pos_not_visited')
        },
        i18nJustified() {
            return this.getI18n(i18nKey, 'TITLES.justified_assignments')
        },
        posCompletedFields() {
            return this.posCheckFields.filter(
                (field) => (field.name !== 'battery' && field.name !== 'file' && field.name !== 'details')
            )
        },
        completedFields() {
            return this.businessUnitCheckFields.filter(
                (field) => (field.name !== 'battery')
            )
        },
        justifiedFields() {
            return this.justificationFields.filter(
                (field) => (field.name !== 'file' && field.name !== 'actions')
            )
        },
    },
    methods: {
        fetchPosVisited(url, params) {
            return PosVisitedService.fetchVuetable(url, params)
        },
        fetchBusinessUnitVisited(url, params) {
            return WidgetService.fetchVuetable('completed-assignments', params)
        },
        fetchPosNotVisited(url, params) {
            return WidgetService.fetchVuetable('uncompleted-assignments-pos', params)
        },
        fetchBusinessUnitNotVisited(url, params) {
            return WidgetService.fetchVuetable('uncompleted-assignments', params)
        },
        fetchPosJustified(url, params) {
            return WidgetService.fetchVuetable('justified-assignments-pos', params)
        },
        fetchBusinessUnitJustified(url, params) {
            return WidgetService.fetchVuetable('justified-assignments', params)
        },
        showModal(parameters) {
            this.parameters = parameters
            this.posVisitedParameters = {
                ...this.parameters,
                with: ['user'],
            }
            this.$refs.formModal.show()
        },
    },
}
</script>
