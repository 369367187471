var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-pdv"},[_c('PageHeader',{attrs:{"title":_vm.getI18n('DASHBOARDS', 'TITLES.pending_by_user')}}),_c('div',{staticClass:"card-body"},[_c('api-mode-vuetable',{ref:"vuetable",attrs:{"fetch-data":_vm.fetch,"display-selection-records-per-page":true,"additional-parameters":_vm.additionalParameters,"fields":_vm.fields,"sort-order":_vm.sortOrder},scopedSlots:_vm._u([{key:"justifications_need_approve",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.justifications_need_approve.length},on:{"onClick":function($event){return _vm.gridJustificationModal(props.rowData)}}})]}},{key:"justifications",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.justifications.length},on:{"onClick":function($event){return _vm.openJustificationApprovedModal(props.rowData)}}})]}},{key:"work_schedule_items",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.work_schedule_items.length},on:{"onClick":function($event){return _vm.openWorkScheduleModal(props.rowData)}}})]}}])})],1),_c('grid-form-modal',{ref:"gridJustificationModal",attrs:{"modal":_vm.modal,"items":_vm.modal.justifications_need_approve,"fields":_vm.justificationFields,"title":_vm.getI18nModified({
                prefix: 'JUSTIFICATION',
                suffix: 'TITLES.justification',
                modifier: 2,
            })},scopedSlots:_vm._u([{key:"cell(details)",fn:function(props){return [_c('div',{staticClass:"table-button-container"},[_c('detail-table-button',{on:{"onClick":function($event){return _vm.openJustificationDetailModal(props.item, false)}}})],1)]}}])}),_c('grid-form-modal',{ref:"gridJustificationApprovedFModal",attrs:{"modal":_vm.modal,"items":_vm.modal.justifications,"fields":_vm.justificationApprovedFields,"title":_vm.getI18nModified({
                prefix: 'JUSTIFICATION',
                suffix: 'TITLES.justification_approved',
                modifier: 2,
            })},scopedSlots:_vm._u([{key:"cell(details)",fn:function(props){return [_c('div',{staticClass:"table-button-container"},[_c('detail-table-button',{on:{"onClick":function($event){return _vm.openJustificationDetailModal(props.item, true)}}})],1)]}}])}),_c('grid-form-modal',{ref:"gridWorkScheduleModal",attrs:{"modal":_vm.modal,"items":_vm.modal.work_schedule_items,"fields":_vm.workScheduleFields,"title":_vm.getI18nModified({
                prefix: 'DASHBOARDS',
                suffix: 'TITLES.unrealized_assignment',
                modifier: 2,
            })}}),_c('justification-modal',{ref:"justificationModal",attrs:{"modal":_vm.justificationModal},on:{"refresh":_vm.closeModalAndRefreshTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }