<template>
    <div class="card card-pdv">
        <PageHeader :title="getI18n('DASHBOARDS', 'TITLES.pending_by_user')" />
        <div class="card-body">
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :display-selection-records-per-page="true"
                :additional-parameters="additionalParameters"
                :fields="fields"
                :sort-order="sortOrder">
                <template slot="justifications_need_approve" slot-scope="props">
                    <count-table-button
                        :value="props.rowData.justifications_need_approve.length"
                        @onClick="gridJustificationModal(props.rowData)"
                    />
                </template>
                <template slot="justifications" slot-scope="props">
                    <count-table-button
                        :value="props.rowData.justifications.length"
                        @onClick="openJustificationApprovedModal(props.rowData)"
                    />
                </template>
                <template slot="work_schedule_items" slot-scope="props">
                    <count-table-button
                        :value="props.rowData.work_schedule_items.length"
                        @onClick="openWorkScheduleModal(props.rowData)"
                    />
                </template>
            </api-mode-vuetable>
        </div>
        <grid-form-modal
            ref="gridJustificationModal"
            :modal="modal"
            :items="modal.justifications_need_approve"
            :fields="justificationFields"
            :title="
                getI18nModified({
                    prefix: 'JUSTIFICATION',
                    suffix: 'TITLES.justification',
                    modifier: 2,
                })
            "
        >
            <template v-slot:cell(details)="props">
                <div class="table-button-container">
                    <detail-table-button @onClick="openJustificationDetailModal(props.item, false)" />
                </div>
            </template>
        </grid-form-modal>
        <grid-form-modal
            ref="gridJustificationApprovedFModal"
            :modal="modal"
            :items="modal.justifications"
            :fields="justificationApprovedFields"
            :title="
                getI18nModified({
                    prefix: 'JUSTIFICATION',
                    suffix: 'TITLES.justification_approved',
                    modifier: 2,
                })
            "
        >
            <template v-slot:cell(details)="props">
                <div class="table-button-container">
                    <detail-table-button @onClick="openJustificationDetailModal(props.item, true)" />
                </div>
            </template>
        </grid-form-modal>
        <grid-form-modal
            ref="gridWorkScheduleModal"
            :modal="modal"
            :items="modal.work_schedule_items"
            :fields="workScheduleFields"
            :title="
                getI18nModified({
                    prefix: 'DASHBOARDS',
                    suffix: 'TITLES.unrealized_assignment',
                    modifier: 2,
                })
            "
        />
        <justification-modal
            ref="justificationModal"
            :modal="justificationModal"
            @refresh="closeModalAndRefreshTable"
        />
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import ApiModeVuetable from '@components/ApiModeVuetable'
import UserInfoPendenciesService from '@src/services/dashboards/UserInfoPendenciesService'
import CountTableButton from '@/src/components/CountTableButton.vue'
import DetailTableButton from '@/src/components/DetailTableButton.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import justificationModal from '@/src/router/views/justification/justificationModal.vue';
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        ApiModeVuetable,
        CountTableButton,
        DetailTableButton,
        GridFormModal,
        justificationModal,
    },
    mixins: [vuetableFormatter],
    props: {
        parameters: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            justificationModal: {

            },
            modal: {
                name: '',
                justifications_need_approve: [],
                justifications: [],
                work_schedule_items: [],
            },
            fields: [
                {
                    name: 'name',
                    title: this.getI18n('COMMON', 'name'),
                    sortField: 'name',
                },
                {
                    name: 'justifications_need_approve',
                    title: this.getI18nModified({
                        prefix: 'JUSTIFICATION',
                        suffix: 'TITLES.justification',
                        modifier: 2,
                    }),
                    sortField: 'justifications_need_approve',
                },
                {
                    name: 'justifications',
                    title: this.getI18nModified({
                        prefix: 'JUSTIFICATION',
                        suffix: 'TITLES.justification_approved',
                        modifier: 2,
                    }),
                    sortField: 'justifications',
                },
                {
                    name: 'work_schedule_items',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.unrealized_assignment',
                        modifier: 2,
                    }),
                    sortField: 'work_schedule_items',
                },
            ],
            justificationFields: [
                {
                    key: 'pointOfSale.name',
                    label: this.getI18n('POS', 'TITLES.pos'),
                    sortable: true,
                },
                {
                    key: 'businessUnit.name',
                    label: this.getI18n(
                        'BUSINESS_UNITS',
                        'TITLES.business_unit'
                    ),
                    sortable: true,
                },
                {
                    key: 'justification_date',
                    label: this.getI18n('JUSTIFICATION', 'FIELDS.justification_date'),
                    sortable: true,
                },
                {
                    key: 'justificationType.name',
                    label: this.getI18n(
                        'JUSTIFICATION',
                        'TITLES.justification_type'
                    ),
                    sortable: true,
                },
                {
                    key: 'details',
                    label: this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'detail',
                        modifier: 2,
                    }),
                    sortable: true,
                },
            ],
            justificationApprovedFields: [
                {
                    key: 'pointOfSale.name',
                    label: this.getI18n('POS', 'TITLES.pos'),
                    sortable: true,
                },
                {
                    key: 'businessUnit.name',
                    label: this.getI18n(
                        'BUSINESS_UNITS',
                        'TITLES.business_unit'
                    ),
                    sortable: true,
                },
                {
                    key: 'justification_date',
                    label: this.getI18n('JUSTIFICATION', 'FIELDS.justification_date'),
                    sortable: true,
                },
                {
                    key: 'justificationType.name',
                    label: this.getI18n(
                        'JUSTIFICATION',
                        'TITLES.justification_type'
                    ),
                    sortable: true,
                },
                {
                    key: 'updated_at',
                    label: this.getI18n('JUSTIFICATION', 'FIELDS.justification_approved_date'),
                    sortable: true,
                },
                {
                    key: 'details',
                    label: this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'detail',
                        modifier: 2,
                    }),
                    sortable: true,
                },
            ],
            workScheduleFields: [
                {
                    key: 'business_unit_name',
                    label: this.getI18n('COMMON', 'business_unit'),
                    sortable: true,
                },
                {
                    key: 'point_of_sale_name',
                    label: this.getI18n('COMMON', 'pos'),
                    sortable: true,
                },
                {
                    key: 'mix_name',
                    label: this.getI18n('MIXES', 'TITLES.mix'),
                    sortable: true,
                },
                {
                    key: 'date',
                    label: this.getI18n('COMMON', 'date'),
                    sortable: true,
                    formatter: this.formatDate,
                },
            ],
        }
    },
    computed: {
        additionalParameters() {
            return this.parameters
        },
    },
    methods: {
        fetch(url, params) {
            return UserInfoPendenciesService.fetchVuetable(url, params)
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.gridJustificationModal.hideModal())
            this.$refs.vuetable.refresh()
        },
        closeModalAndRefreshTable() {
            this.refreshTable()
        },
        gridJustificationModal(data) {
            this.modal = data
            this.$nextTick(() => this.$refs.gridJustificationModal.showModal())
        },
        openJustificationApprovedModal(data) {
            this.modal = data
            this.$nextTick(() => this.$refs.gridJustificationApprovedFModal.showModal())
        },
        openJustificationDetailModal(data, readOnly) {
            this.justificationModal = data
            this.$nextTick(() => this.$refs.justificationModal.showModal(readOnly))
        },
        openWorkScheduleModal(data) {
            this.modal = data
            this.$nextTick(() => this.$refs.gridWorkScheduleModal.showModal())
        },
    },
}
</script>
