<template>
    <div class="card card-pdv">
        <PageHeader :title="getI18n('DASHBOARDS', 'TITLES.planning_by_user')" />
        <div class="card-body">
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :display-selection-records-per-page="true"
                :additional-parameters="additionalParameters"
                :fields="fields"
                :sort-order="sortOrder"
            >
                <template slot="done_percentage" slot-scope="props">
                    <progress-bar-table :value.sync="props.rowData.done_percentage" />
                </template>
                <template slot="done_percentage_with_justifications" slot-scope="props">
                    <progress-bar-table :value.sync="props.rowData.done_percentage_with_justifications" />
                </template>
            </api-mode-vuetable>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import ApiModeVuetable from '@components/ApiModeVuetable'
import ProgressBarTable from '@components/ProgressBarTable'
import UserInfoService from '@src/services/dashboards/UserInfoService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        ApiModeVuetable,
        ProgressBarTable,
    },
    mixins: [vuetableFormatters],
    props: {
        parameters: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'planned',
                    title: this.getI18n(i18nKey, 'TITLES.planned'),
                    sortField: 'point_of_sales_count',
                },
                {
                    name: 'checked',
                    title: this.getI18n(i18nKey, 'TITLES.checked'),
                    sortField: 'point_of_sales_checks_count',
                },
                {
                    name: 'absence',
                    title: this.getI18n(i18nKey, 'TITLES.justifications_with_allowance'),
                    sortField: 'justifications_absence_count',
                },
                {
                    name: 'justified',
                    title: this.getI18n(i18nKey, 'TITLES.justified_without_allowance'),
                    sortField: 'justifications_count',
                },
                {
                    name: 'added',
                    title: this.getI18n(i18nKey, 'TITLES.added'),
                    sortField: 'point_of_sales_added_by_app',
                },
                {
                    name: 'done_percentage',
                    title: this.getI18n(i18nKey, 'TITLES.done_percentage'),
                },
                {
                    name: 'done_percentage_with_justifications',
                    title: this.getI18n(i18nKey, 'TITLES.done_percentage_with_justifications'),
                },
            ],
        }
    },
    computed: {
        additionalParameters() {
            return this.parameters
        },
    },
    methods: {
        percentileClass(percentile) {
            return 'text-center aligned font-weight-bold ' +  (percentile >= 80
                    ? 'table-success'
                    : percentile >= 50
                    ? 'table-warning'
                    : 'table-danger')
        },
        fetch(url, params) {
            return UserInfoService.fetchVuetable(url, params)
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>
<style>
td:has(div.text-danger) {
    background-color: #fbcfd6;
}
</style>
