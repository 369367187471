<template>
    <data-modal
        ref="dataModal"
        :fetch-data="fetch"
        :title="titleFormatted"
        :fields="fields"
        :additional-parameters="additionalParameters"
        :sort-order="sortOrder"
    >
    </data-modal>
</template>

<script>
import PosNotVisitedService from '@src/services/dashboards/PosNotVisitedService'
import DataModal from '@/src/components/dataModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

export default {
    components: {
        DataModal,
    },
    mixins: [vuetableFormatters],
    props: {
        title: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'date',
                    direction: 'desc',
                },
            ],
            additionalParameters: {},
            data: [],
            filterText: '',
            fields: [
                {
                    name: 'date',
                    title: this.getI18n('COMMON', 'date'),
                    sortField: 'date',
                    formatter: this.formatDate,
                },
                {
                    name: 'user_name',
                    title: this.getI18n('COMMON', 'user_name'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'mix_name',
                    title: this.getI18n('COMMON', 'mix_name'),
                    sortField: 'mixes|mixes.name',
                },
            ],
            perPage: 5,
        }
    },
    computed: {
        titleFormatted() {
            return `${this.getI18n('COMMON', 'pos')}: ${this.title}`
        },
    },
    methods: {
        fetch(url, params) {
            return PosNotVisitedService.fetchVuetableById(url, params)
        },
        showModal(posId, parameters) {
            this.additionalParameters = {
                ...parameters,
                id: posId,
            }

            this.$nextTick(() => {
                this.$refs.dataModal.show()
            })
        },
        hideModal() {
            this.$refs.dataModal.hide()
        },
    },
}
</script>
