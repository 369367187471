<template>
    <div class="card card-pdv">
        <PageHeader :title="getI18n('DASHBOARDS', 'TITLES.pos_not_visited')" />
        <div class="card-body">
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :display-selection-records-per-page="true"
                :additional-parameters="additionalParameters"
                :fields="fields"
                :sort-order="sortOrder">
                <template slot="justifications" slot-scope="props">
                    <count-table-button
                        :value="props.rowData.justifications_count"
                        @onClick="openJustificationModal(props.rowData)"
                    />
                </template>
                <template slot="unrealized_assignments" slot-scope="props">
                    <a
                        href="javascript: void(0);"
                        @click="openUnrealizedModal(props.rowData)"
                    >
                        <i class="fe-eye icon-blue" />
                    </a>
                </template>
            </api-mode-vuetable>
        </div>
        <posNotVisitedUnrealizedWorkSchedules
            ref="posNotVisitedUnrealizedWorkSchedules"
            :title="pointOfSaleName"
        />
        <posNotVisitedJustifications
            ref="posNotVisitedJustifications"
            :title="pointOfSaleName"
        />
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import ApiModeVuetable from '@components/ApiModeVuetable'
import PosNotVisitedService from '@src/services/dashboards/PosNotVisitedService'
import posNotVisitedUnrealizedWorkSchedules from '@views/dashboards/resume/posNotVisitedUnrealizedWorkSchedules'
import posNotVisitedJustifications from '@views/dashboards/resume/posNotVisitedJustifications'
import CountTableButton from '@/src/components/CountTableButton.vue'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        ApiModeVuetable,
        posNotVisitedJustifications,
        posNotVisitedUnrealizedWorkSchedules,
        CountTableButton,
    },
    props: {
        parameters: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            pointOfSaleName: '',
            fields: [
                {
                    name: 'name',
                    title: this.getI18n('COMMON', 'name'),
                    sortField: 'name',
                },
                {
                    name: 'justifications',
                    title: this.getI18n(i18nKey, 'TITLES.justified'),
                    sortField: 'justifications_count',
                },
                {
                    name: 'unrealized_assignments',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.unrealized_assignment',
                        modifier: 2,
                    }),
                },
            ],
        }
    },
    computed: {
        additionalParameters() {
            return this.parameters
        },
    },
    methods: {
        fetch(url, params) {
            return PosNotVisitedService.fetchVuetable(url, params)
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        openUnrealizedModal(data) {
            this.pointOfSaleName = data.name
            this.$refs.posNotVisitedUnrealizedWorkSchedules.showModal(data.id, this.additionalParameters)
        },
        openJustificationModal(data) {
            this.pointOfSaleName = data.name
            this.$refs.posNotVisitedJustifications.showModal(data.id, this.additionalParameters)
        },
    },
}
</script>
