<template>
    <data-modal
        ref="dataModal"
        :fetch-data="fetch"
        :title="titleFormatted"
        :fields="fields"
        :additional-parameters="additionalParameters"
        :sort-order="sortOrder"
    >
    </data-modal>
</template>

<script>
import DataModal from '@/src/components/dataModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import jusFields from '@src/fields/justificationFields'
import JustificationService from '@/src/services/JustificationService'

export default {
    components: {
        DataModal,
    },
    mixins: [vuetableFormatters, jusFields],
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sortOrder: [
                {
                    field: 'justification_date',
                    direction: 'desc',
                },
            ],
            additionalParameters: {},
            data: [],
            perPage: 5,
        }
    },
    computed: {
        fields() {
            return this.justificationFields.filter(
                (field) => field.name !== 'actions'
            )
        },
        posId() {
            return this.pointOfSaleId
        },
        titleFormatted() {
            return `${this.getI18n('COMMON', 'pos')}: ${this.title}`
        },
    },
    methods: {
        fetch(url, params) {
            return JustificationService.fetchVuetable(url, params)
        },
        showModal(posId, parameters) {
            this.additionalParameters = {
                ...parameters,
                with: [
                    'user',
                    'justificationType',
                    'file',
                    'pointOfSale',
                    'businessUnit',
                ],
                point_of_sale_id: [posId],
                justification_date: {
                    from: parameters.start,
                    to: parameters.end,
                },
            }

            this.$nextTick(() => {
                this.$refs.dataModal.show()
            })
        },
        hideModal() {
            this.$refs.dataModal.hide()
        },
    },
}
</script>
