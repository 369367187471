<template>
    <b-row>
        <b-col md="4">
            <resume-chart-card
                ref="chartCompletedAssignments"
                :title="i18nCompletedAssignments"
                :busy="loadingResume"
                :display-more-button="true"
                @onClick="openVisitsModal"
                />
        </b-col>
        <b-col md="4">
            <resume-chart-card
                ref="chartWorkingUsers"
                :title="i18nWorkingUsers"
                :busy="loadingResume"
                :display-more-button="true"
                @onClick="openUsersWorkModal"
            />
        </b-col>
        <b-col md="4">
            <resume-chart-card
                ref="chartUnplannedUsers"
                :title="i18UnplannedUsers"
                :busy="loadingResume"
                :display-more-button="true"
                @onClick="openUsersPlannedModal"
            />
        </b-col>
        <resume-visits-modal ref="resumeVisitsModal" />
        <resume-users-work-modal ref="resumeUsersWorkModal" />
        <resume-users-planned-modal ref="resumeUsersPlannedModal" />
    </b-row>
</template>

<script>
import ResumeService from '@src/services/dashboards/ResumeService'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import ResumeChartCard from '@/src/components/dashboards/ResumeChartCard.vue'
import ResumeVisitsModal from '@/src/router/views/dashboards/resume/resumeVisitsModal.vue'
import ResumeUsersWorkModal from '@/src/router/views/dashboards/resume/resumeUsersWorkModal.vue'
import ResumeUsersPlannedModal from '@/src/router/views/dashboards/resume/resumeUsersPlannedModal.vue'
import userFields from '@src/fields/userFields'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        ResumeChartCard,
        ResumeVisitsModal,
        ResumeUsersWorkModal,
        ResumeUsersPlannedModal,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        userFields,
    ],
    data() {
        return {
            i18nKey,
            loadingResume: true,
            parameters: {},
            widgetCompletedAssignments: {},
            widgetWorkingUsers: {},
            widgetUnplannedUsers: {},
        }
    },
    validations: {},
    computed: {
        i18nCompletedAssignments() {
            return this.getI18n(i18nKey, 'TITLES.pos_visited')
        },
        i18nWorkingUsers() {
            return this.getI18n(i18nKey, 'TITLES.working_users')
        },
        i18UnplannedUsers() {
            return this.getI18n(i18nKey, 'TITLES.unplanned_users')
        },
    },
    methods: {
        openVisitsModal() {
            this.$refs.resumeVisitsModal.showModal(this.parameters)
        },
        openUsersWorkModal() {
            this.$refs.resumeUsersWorkModal.showModal(this.parameters)
        },
        openUsersPlannedModal() {
            this.$refs.resumeUsersPlannedModal.showModal(this.parameters)
        },
        resumeFetch(parameters) {
            this.loadingResume = true
            this.parameters = parameters

            ResumeService.fetchAll(parameters)
                .then((response) => {
                    const widgetData = response.data.data
                    this.widgetCompletedAssignments =
                        {
                            data: [
                                {
                                    value: widgetData.assignments_planned,
                                    color: '#5899EF',
                                    type: 'total',
                                    title: this.getI18n('PAGINATION', 'total'),
                                },
                                {
                                    value: widgetData.completed_assignments,
                                    color: '#89E09C',
                                    type: 'percentage',
                                    title: this.getI18n(i18nKey, 'TITLES.checked'),
                                },
                                {
                                    value: widgetData.justified_absence,
                                    color: '#ED6112',
                                    type: 'percentage',
                                    title: this.getI18n(i18nKey, 'TITLES.justifications_with_allowance'),
                                },
                                {
                                    value: widgetData.justified_assignments - widgetData.justified_absence,
                                    color: '#EDB012',
                                    type: null,
                                    title: this.getI18n(i18nKey, 'TITLES.justified_without_allowance'),
                                },
                            ],
                        }

                    this.widgetWorkingUsers =
                        {
                            data: [
                                {
                                    value: widgetData.total_users,
                                    color: '#5899EF',
                                    type: 'total',
                                    title: this.getI18n('PAGINATION', 'total'),
                                },
                                {
                                    value: widgetData.working_users,
                                    color: '#89E09C',
                                    type: 'percentage',
                                    title: this.getI18n(i18nKey, 'TITLES.working_time'),
                                },
                                {
                                    value: widgetData.not_working_users,
                                    color: '#EDB012',
                                    type: null,
                                    title: this.getI18n(i18nKey, 'TITLES.inactive'),
                                },
                            ],
                        }

                    this.widgetUnplannedUsers =
                        {
                            name: 'unplanned_users',
                            data: [
                                {
                                    value: widgetData.total_users,
                                    color: '#5899EF',
                                    type: 'total',
                                    title: this.getI18n('PAGINATION', 'total'),
                                },
                                {
                                    value: widgetData.planned_users,
                                    color: '#89E09C',
                                    type: null,
                                    title: this.getI18n(i18nKey, 'TITLES.with_workschedule'),
                                },
                                {
                                    value: widgetData.unplanned_users,
                                    color: '#CE0404',
                                    type: 'percentage',
                                    title: this.getI18n(i18nKey, 'TITLES.no_workschedule'),
                                },
                            ],
                        }

                    this.$refs.chartCompletedAssignments.showChart(this.widgetCompletedAssignments)
                    this.$refs.chartUnplannedUsers.showChart(this.widgetUnplannedUsers)
                    this.$refs.chartWorkingUsers.showChart(this.widgetWorkingUsers)

                    this.loadingResume = false
                })
                .catch((err) => {
                    this.loadingResume = false
                    return err
                })
        },
    },
}
</script>
