import BaseService from '@src/services/BaseService'

class PosNotVisitedService extends BaseService {
    constructor() {
        super('dashboards/pos-not-visited')
    }

    fetchVuetableById(apiUrl, { params } = { params: {} }) {
        const parameters = this.getSorting(params)
        return this.fetchById(params.id, parameters)
    }
}

export default new PosNotVisitedService()
