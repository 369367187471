<template>
    <b-row>
        <div class="progress progress-pdv mr-2">
            <b-progress-bar v-if="value <= 40" :value="value" :max="100" variant="danger" class="progress-pdv"></b-progress-bar>
            <b-progress-bar v-else-if="value <= 80" :value="value" :max="100" variant="warning" class="progress-pdv"></b-progress-bar>
            <b-progress-bar v-else :value="value" :max="100" variant="success" class="progress-pdv"></b-progress-bar>
        </div>
        {{ formatPercentageInteger(value) }}
    </b-row>
</template>

<script>
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'

export default {
    mixins: [
        vuetableFormatters,
    ],
    props: {
        value: {
            type: Number,
            required: true,
            default: 0
        },
    },
}
</script>
