import BaseService from '@src/services/BaseService'

class WidgetService extends BaseService {
    constructor() {
        super('dashboards/widgets')
    }
}

export default new WidgetService()

